@import 'variables';

// <editor-fold desc="TOOLTIP">

[data-toggle="cr-tooltip"] {
  position: relative;
}

.cr-tooltip {
  position: fixed;
  background-color: @color-default-5;
  padding: .5rem;
  border-radius: @radius-default;
  max-width: 20rem;
  z-index: @max-z-index + 100;
  pointer-events: none;
  margin: 0 !important;

  font-family: @font-primary;
  text-align: center;
  font-size: 14px;
  color: white;
  text-transform: none;
  line-height: 1.2em;
  white-space: normal;

  &:after {
    position: absolute;
    content: '';
  }

  &-top,
  &-bottom {
    transform: translateX(-50%);

    &:after {
      left: 50%;
      border-left: .5rem solid transparent;
      border-right: 0 solid transparent;
    }
  }

  &-top:after {
    top: 100%;
    border-bottom: .5rem solid @color-default-5;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &-bottom:after {
    bottom: 100%;
    border-top: .5rem solid @color-default-5;
    transform: translate(-50%, 50%) rotate(-45deg);
  }

  &-left,
  &-right {
    transform: translateY(-50%);

    &:after {
      top: 50%;
      border-top: .5rem solid transparent;
      border-bottom: 0 solid transparent;
    }
  }

  &-left:after {
    left: 100%;
    border-right: .5rem solid @color-default-5;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &-right:after {
    right: 100%;
    border-left: .5rem solid @color-default-5;
    transform: translate(50%, -50%) rotate(45deg);
  }
}

// </editor-fold>
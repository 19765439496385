@import '../_variables';

.cr-popover() {
  position: relative;

  .cr-popover-content {
    position: absolute;
    display: none;
    background-color: @color-default-1;
    padding: 1rem;
    border: 2px solid @color-default-2;
    border-radius: @radius-default;
    box-shadow: @default-shadow;
    width: 100%;
    z-index: @header-z-index + 1;

    // seta
    &:before {
      position: absolute;
      content: "";
    }

    // elemento falso por hover
    &:after {
      position: absolute;
      content: '';
      left: 0;
      height: 1rem;
      width: 100%;
    }
  }

  &.debug .cr-popover-content {
    display: block;
  }
}

.cr-popover-hover() {
  &:hover .cr-popover-content,
  &.cr-popover-show .cr-popover-content,
  .cr-popover-content:hover {
    @media (hover: hover) and (pointer: fine) {
      display: block;
    }
  }
}

.cr-popover-top() {
  .cr-popover-content {
    bottom: calc(100% - .5rem);
    margin-bottom: 1rem;

    &:before {
      border-top: 1rem solid @color-default-2;
      bottom: -1rem;
    }

    &:after {
      bottom: -1rem;
    }
  }
}

.cr-popover-bottom() {
  .cr-popover-content {
    top: calc(100% - .5rem);
    margin-top: 1rem;

    &:before {
      border-bottom: 1rem solid @color-default-2;
      top: -1rem;
    }

    &:after {
      top: -1rem;
    }
  }
}

.cr-popover-left() {
  .cr-popover-content {
    left: 0;

    &:before {
      border-left: 0 solid transparent;
      border-right: 1rem solid transparent;
      left: .5rem;
    }
  }
}

.cr-popover-right() {
  .cr-popover-content {
    right: 0;

    &:before {
      border-left: 1rem solid transparent;
      border-right: 0 solid transparent;
      right: .5rem;
    }
  }
}

.cr-popover-left-top {
  .cr-popover();
  .cr-popover-left();
  .cr-popover-top();
}

.cr-popover-right-top {
  .cr-popover();
  .cr-popover-right();
  .cr-popover-top();
}

.cr-popover-left-bottom {
  .cr-popover();
  .cr-popover-left();
  .cr-popover-bottom();
}

.cr-popover-right-bottom,
.cr-popover-right-bottom-sm-up {
  .cr-popover();
  .cr-popover-right();
  .cr-popover-bottom();
}

.cr-popover-left-top {
  .cr-popover-hover();
}

.cr-popover-right-top {
  .cr-popover-hover();
}

.cr-popover-left-bottom {
  .cr-popover-hover();
}

.cr-popover-right-bottom {
  .cr-popover-hover();
}

@media (@min-screen-sm) {
  .cr-popover-right-bottom-sm-up {
    .cr-popover-hover();
  }
}
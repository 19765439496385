@import '../_variables';

.btn {
  text-transform: uppercase;
  font-size: 1.14em;
  background-color: @color-default-5;
  border: 1px solid @color-default-5;
  color: white;

  &:not([disabled]):not(.btn-disabled) {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: @color-accent-1;
        border-color: @color-accent-2;
        color: white;
      }
    }

    &:active {
      background-color: @color-accent-2;
      box-shadow: none;
    }
  }

  &.btn-success {
    &:not([disabled]):not(.btn-disabled) {
      background-color: @color-default-5;
      border-color: @color-default-5;

      &:hover {
        background-color: @color-primary-5 !important;
        border-color: @color-primary-5 !important;
      }
    }

  }
}
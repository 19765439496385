@font-face {
  font-family: 'Proxima Nova';
  src: url("https://d37qth7w71a0im.cloudfront.net/fonts/proxima-nova/proxima_nova_regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("https://d37qth7w71a0im.cloudfront.net/fonts/proxima-nova/proxima_nova_bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
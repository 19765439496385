@import '../../submodules/src/styles/alert';
@import '../../submodules/src/styles/modal';
@import '../../submodules/src/styles/tables';
@import '../../submodules/src/styles/tooltip';
@import '_variables';
@import 'element/_mixins';
@import 'element/_buttons';
@import 'element/_icons-mixins';
@import 'element/footer';
@import 'element/_modal-promo';


@import 'element/_fonts';
@import 'element/_popover';
@import 'element/_to-review';
@import 'element/product-list';
@import 'element/product-list-item';


.icon-gift();
.icon-offer-gift();
.icon-chevron-right();
.icon-info();
.icon-tag();

// basic
.icon-close();
.icon-chevron-down();
.icon-chevron-down-2();
.icon-chevron-up();
.icon-chevron-up-2();
.icon-chevron-left();
.icon-arrow-right();
.icon-arrow-left();

// header + footer
.icon-carrier();
.icon-t-shirt();
.icon-heart();
.icon-exchange();
.icon-credit-card();
.icon-of-people();
.icon-vegan();
.icon-create-is-our-soul();
.icon-no-plastic();
.icon-wishlist();
.icon-trash();
.icon-block-view();
.icon-list-view();
.icon-cashback();
// </editor-fold>


::-webkit-scrollbar-thumb {
    &:hover {
        background: @color-default-5;
    }

    &:active {
        background: @color-default-5;
    }
}

//<editor-fold desc="FRAMES">
.frame {
    background: transparent;
    // background-color: @color-default-1;
    border-radius: @radius-default;
    padding: 1rem;
}

.full-size {
    width: 100%;
}

.frame-outline {
    background-color: transparent;
    border-radius: @radius-default;
    padding: 1rem;
    //border: 2px solid @color-default-2;
}

.titleOrderConfirmation {
    font-family: @roboto;
    font-size: 20px;
    font-style: italic;
    line-height: 23.44px;
}

.frame-hover {
    &:extend(.frame);

    @media (hover: hover) and (pointer: fine) {
        transition: background-color @transition-default;
        cursor: pointer;

        &:hover {
            background-color: @color-default-2;
        }
    }
}

.frame-inverse {
    &:extend(.frame);
    background-color: @color-default-5;
    color: @color-default-1;

    h1,
    h2,
    h3,
    h4 {
        color: @color-default-1;
    }
}

.frame-support {
    &:extend(.frame);
    background-color: @color-support-1;
    color: @color-default-1;

    h1,
    h2,
    h3,
    h4,
    a {
        color: @color-default-1;
    }
}

//</editor-fold>

// <editor-fold desc="ICONS">

// social
.icon-instagram();
.icon-facebook();
.icon-twitter();
.icon-youtube();
.icon-prosa();
.icon-tiktok();
.icon-pinterest();
.icon-spotify();

// history
.icon-register();

// </editor-fold>

//<editor-fold desc="DIVIDERS">

.spaced-dots {
    background-image: linear-gradient(to right, @color-default-4 20%, transparent 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
    height: 3px;
}

//</editor-fold>

//<editor-fold desc="LISTS">
ul {
    &.d-flex,
    &.row,
    &.flex-grid {
        list-style: none;
        padding-left: 0;
    }
}

//</editor-fold>

// <editor-fold desc="TABLES">

table {
    th,
    tfoot td {
        text-transform: uppercase;
    }
}

// </editor-fold>

// <editor-fold desc="ALERTS">

[class*='alert-'] {
    .text-yanone();
    font-size: 1.2rem;

    // todo remover
    button.close {
        color: white;
    }

    .icon-cr.icon-close {
        display: block;
        float: right;
        height: 1.8rem;
        width: 1.8rem;
        opacity: 0.7;
        cursor: pointer;
    }
}

// </editor-fold>

// <editor-fold desc="ANIMATIONS">
@keyframes openDrawer {
    0% {
        display: block;
        transform: translateX(-100%);
        box-shadow: none;
    }
    100% {
        display: block;
        transform: translateX(0);
        box-shadow: @default-shadow;
    }
}

@keyframes closeDrawer {
    0% {
        display: block;
        transform: translateX(0);
        box-shadow: @default-shadow;
    }
    100% {
        display: block;
        transform: translateX(-100%);
        box-shadow: none;
    }
}

@keyframes go-back {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(10px);
    }
}

@keyframes showOverlay {
    0% {
        display: block;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 0.3;
    }
}

@keyframes hideOverlay {
    0% {
        display: block;
        opacity: 0.3;
    }
    100% {
        display: block;
        opacity: 0;
    }
}

@keyframes showFlashSearch {
    0%,
    50%,
    100% {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}

// </editor-fold>

// <editor-fold desc="CART">
.cart-display {
    display: flex !important;
    flex-direction: column-reverse !important;
}

.cart-display-AB {
    flex-direction: column !important;
}

.progress-scroll {
    width: 100%;
    height: 10px;
    border-radius: 10px;
    background-color: rgba(184, 204, 215, 0.5);
}

.progress-scroll div {
    height: 100%;
    border-radius: 10px;
    background-color: #1eb3d6;
}

.coupon-button {
    background-color: white;
    color: black;
    font-size: 13px;
    border: 1px solid;
    border-radius: unset;

    .coupon-text {
        width: 7ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: '';
        margin-top: 0;
        margin-bottom: 0;
    }

    @media (max-width: 513px) {
        width: 50% !important;
    }
}

#cart-products {
    .cart-product-info {
        display: flex;
        width: 100%;
        //align-items: center;
    }

    .badge-default, .badge-danger, .badge-primary {
        font-size: 0.86rem;
    }

    .badge-product-color {
        border-radius: 50%;
        display: inline-block;
        height: 19px;
        width: 19px;
        border-style: solid;
        border-width: 1px;
    }

    .product-list-item-subtitle {
        font-size: 14px;

        .color-name {
            font-size: 12px;
        }
    }
}

.ellipsis-text {
    @media (max-width: 768px) {
        width: 14ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.mobile-cart-deadline {
    @media (min-width: 513px) {
        display: none;
    }
}

.hidden-mobile {
    @media (max-width: 513px) {
        display: none;
    }
}

.hidden-lateral-cart {
    display: none;
}

.select-and-prices {
    margin-bottom: 2rem;

    @media (max-width: 513px) {
        margin-bottom: 0;
    }

    @media (max-width: 570px) {
        justify-content: space-between;
    }
}

.select-and-prices-special {
    margin-bottom: 1rem;

    @media (@min-screen-xs) {
        margin-bottom: 0 !important;
    }

    @media (@max-screen-xs) {
        display: flex;
    }
}

/*start barra de progresso sacola*/
.progress-scroll {
    width: 100%;
    height: 10px;
    border-radius: 10px;
    background-color: rgba(184, 204, 215, 0.5);
}

.progress-scroll div {
    height: 100%;
    border-radius: 10px;
    background-color: #1eb3d6;
}

/*end*/

/*start barra vertical sacola vazia*/
.vertical-row {
    margin-top: 45%;
    width: 7px;
    height: 450px;
    background: linear-gradient(
        180deg,
        #efb758 3.88%,
        #ffdb9e 8.35%,
        #c94e36 13.37%,
        #ed8c9d 29.61%,
        #719fda 51.56%,
        #5a40a1 61.22%,
        #5a40a1 80.62%,
        #e94e3a 96.87%
    );
}

/*end*/

a:hover,
button:hover {
    .animation-go-back {
        animation: go-back 0.5s infinite alternate;
    }
}

.flash-search {
    animation: showFlashSearch 3s linear 0s infinite normal backwards;
}

.select-mobile {
    border-style: solid;
    border-width: 1px;
    border-radius: 0;
    line-height: 1.7rem;
}

.text-or {
    font-family: @roboto;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-60 {
    margin-top: 70px;
}

.hr-1 {
    position: absolute;
    left: 0%;
    right: 1.04%;
    margin-left: 3%;
    margin-right: 3%;
    border: 0.5px solid #1e1e1d;
}

.hr-2 {
    width: 60%;
    border: 2px solid #1e1e1d;
    margin-left: 20%;
}

.hr-3 {
    width: 45%;
    border: 2px solid #1e1e1d;
    margin-left: 28%;
}

.hr-4 {
    width: 80%;
    height: 7px;
    margin-left: 10%;
    background: linear-gradient(
        90deg,
        #efb758 3.88%,
        #ffdb9e 8.35%,
        #c94e36 13.37%,
        #ed8c9d 29.61%,
        #719fda 51.56%,
        #5a40a1 61.22%,
        #5a40a1 80.62%,
        #e94e3a 96.87%
    );
}

.text-password-login {
    font-family: @roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    margin-left: 40%;
    color: #1e1e1d;
}

.grid {
    display: grid;
    grid-column-gap: 3%;
    grid-template-columns: repeat(2, 1fr);
    max-width: calc(400px + 2 * 1rem);
}

.text-register {
    font-family: @roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.01em;
    text-align: left;
}

.text-register-login {
    font-family: @roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.01em;
}

.text-back {
    font-family: @roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
}

.button-login {
    position: absolute;
    width: 100%;
    height: 47px;
}

.text-login {
    font-family: @roboto;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-align: center;
}

.button-close {
    float: right;
    font-weight: 800;
    color: #1a1a1a;
    font-size: 15px;
}

.div-social {
    margin-top: 8%;
    margin-bottom: 8%;
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
    padding: 6px;
}

.social-signin > * {
    flex: 1 0 40%;
}

.facebook-login {
    display: flex;
    color: #444;
    background: #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    white-space: nowrap;
    border: 1px solid #dadce0;
    cursor: pointer;
}

.facebook-login:hover {
    /*box-shadow: 0.5px 0.5px 0.5px #32b7e8;*/
    box-shadow: 0px 0px 0px 0.5px #1db2d6;
}

.facebook-icon {
    background: url(https://chico-rei.imgix.net/icones/icon-facebook.jpg) no-repeat;
    padding-left: 25px;
    height: 65px;
    margin-top: 18%;
    margin-left: 20%;
    margin-right: 2%;
}

//.g_id_signin{
//  div:first-child{
//    box-shadow: none;
//  }
//}

.content-login {
    width: 500px;
}

.text-access {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    flex: 1;
}

.button-container-login {
    display: flex;
    gap: 6px;
}

.title-login {
    height: 3vh;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    align-items: center;
}

.text-login-top {
    font-family: @roboto;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.02em;
    text-align: left;
    align-items: center;
    display: flex;
}

// </editor-fold>

// <editor-fold desc="SEARCH BAR">
.search-bar {
    a {
        text-decoration: none;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    li {
        font-size: 15px;
        line-height: 2rem;
    }

    li:hover {
        cursor: pointer;
    }
}

// </editor-fold>

// <editor-fold desc="Horizontal Scroll">

.scrollbar-box-shadow-right {
    width: 5rem;
    border: none;
    position: absolute;
    z-index: 2;
    right: 0;
    text-decoration: none;
    cursor: pointer;
    bottom: 0;
}

.scrollbar-box-shadow-left {
    width: 5rem;
    border: none;
    position: absolute;
    z-index: 2;
    left: 0;
    text-decoration: none;
    cursor: pointer;
    bottom: 0;
}

.scrollbar-button-right {
    width: 4rem;
    height: 2.5rem;
    border: none;
    background-color: white;
    opacity: 0.8;
    position: relative;
    z-index: 2;
    right: 0.625rem;
    text-decoration: none;
    cursor: pointer;
    text-align: right;
    top: 35%;
}

.scrollbar-button-left {
    width: 4rem;
    height: 2.5rem;
    border: none;
    background-color: white;
    opacity: 0.8;
    position: relative;
    z-index: 2;
    left: 0.625rem;
    text-decoration: none;
    cursor: pointer;
    text-align: left;
    top: 35%;
}

.horizontal-scrollbar {
    height: 2px;
    background-color: #a7a6a5;
}

// </editor-fold>

// <editor-fold desc="Presente Carrinho Lateral"
ul.cart-products-list {
    padding-left: 0;

    li {
        display: flex;
        flex-wrap: wrap;
        //align-items: center;

        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        > :not(:last-child) {
            margin-right: 1rem;
        }

        > :not(.cart-product-info) {
            flex-shrink: 0;
            flex-grow: 0;
        }

        .cart-product-info {
            display: flex;
            width: 100%;
            //align-items: center;
        }

        .product-list-item-price {
            @media (max-width: 513px) {
                flex-basis: auto;
            }
        }

        .cr-select {
            flex-basis: 50px; // mesma largura da imagem
            width: 4.5rem;
            text-align-last: center;
        }

        @media (@min-screen-md) {
            flex-wrap: nowrap;

            .cart-product-info {
                flex: 1 1 auto;
                width: auto;
            }

            input {
                flex-basis: 4rem;
                text-align: center;
            }

            .cart-product-remove {
                margin-left: 0;
            }
        }
    }

    .flex:not(.flex-column) {
        align-items: center;
    }

    .aspect-shirt {
        padding-bottom: 50%;
        @media (max-width: 513px) {
            padding-bottom: 75%;
        }
    }
}

.cart-gift-button {
    background-color: white;
    color: black;
    font-size: 13px;
    border: 1px solid;
    border-radius: unset;

    @media (@max-screen-xs) {
        text-wrap: normal;
        line-height: 1.5rem;
    }

    .gift-button-div {
        @media (@max-screen-xs) {
            padding: 1rem 3rem;
        }
    }
}

#cart-gift-options {
    @media (max-width: 320px) {
        padding: 0;
    }

    .gift-options-ab {
        font-family: @font-proxima-nova;
        display: flex;

        img {
            max-width: none !important;
        }

        .icon-add-to-cart {
            position: absolute;
            bottom: 0;
            margin-bottom: 2%;
            margin-left: 73%;
            cursor: pointer;
            z-index: 3;

            @media(max-width: 320px){
                margin-left: 70%;
            }

            .icon-cr {
                width: 30px;
                height: 30px;
                background-color: whitesmoke;
                border-radius: 20px;
                padding: 18px
            }
        }
    }

    ul {
        padding-left: 0 !important;
    }

    .horizontal-list > * {
        flex: auto !important;
        margin-right: 0 !important;
    }
}

.finish-cart-AB {
    width: 100vw;
    max-width: 550px;
    background-color: #F7F6F5;
    font-family: @roboto;
    display: flex;
    justify-content: space-around;
    z-index: 10;

    button {
        width: 63% !important;
        margin: 1rem !important;
    }
}

.fixed-button {
    position: fixed;
    bottom: 0;
    right: 0;
    box-shadow: 0 -5px 11px -11px black;
    transition: all 0.5s;
}

.positioned-button {
    bottom: -100px;
}

.options-list-item {
    flex-wrap: nowrap !important;
}

.cart-special-options-image {
    display: flex;
    margin-right: 0.5rem !important;
}

.special-option-text {
    color: @color-default-5;
    font-size: 14px;
    gap: 0.5rem;
    &.max-fixed{
        max-width: 270px;

        @media(@max-screen-xs) {
            max-width: 180px;
        }
    }

    @media(@max-screen-xs) {
        margin-bottom: 0 !important;
    }
}

.special-option-stock {
    font-weight: 700;
    font-size: 12px;
    color: @color-default-5;
}

.special-option-button {
    background-color: white;
    color: black;
    font-size: 13px;
    border: 1.8px solid;
    border-radius: unset;
    width: auto;
    @media (@max-screen-sm) {
        line-height: 2.7rem;
    }

    @media (@max-screen-xs) {
        padding: 0 5px !important;
    }
}

.cart-cashback-toggle {
    border: 1px solid var(--color-default-3);
    padding: 13px 0 9px 10px;
    background-color: var(--color-input-background);

    label::after {
        top: 50% !important;
    }

    .cr-switch:not(:checked) + label::before {
        background-color: var(--color-default-4) !important;
    }

    .cr-switch:checked + label::before {
        background-color: #6B8CC4 !important;
    }

    .cr-switch + label {
        margin-top: -2px !important;
    }
}

.checkout-cashback {
    .cr-switch:not(:checked) + label::before {
        background-color: var(--color-default-4) !important;
    }

    .cr-switch:checked + label::before {
        background-color: #6B8CC4 !important;
    }

    .cr-switch + label {
        margin-left: -1rem;
    }
}


.text-cashback{
    color: #0B6B07;
    margin: 0;
}

.cashback-checkout{
    border-left: 2px solid #0B6B07;
    background: #F4F4F4;
    padding: 8px;

    p{
        color: #0B6B07;
    }
}

// </editor-fold>

// <editor-fold desc="Wishlist"
span:hover {
    .animation-go-back-wishlist {
        animation: go-back 0.5s infinite alternate;
    }
}
// </editor-fold>

.full-image{
    img{
        margin: 0 auto;
    }
}

.open-gifts-button {
    text-transform: uppercase;
    background-color: white;
    border-color: var(--color-default-5);
    color: var(--color-default-text-5);
    font-size: 1.2rem;
    line-height: 2.5rem;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    @media (@max-screen-xs) {
        font-size: 1rem;
    }
    @media (max-width: 350px) {
        font-size: 12px;
    }
}
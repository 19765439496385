@import '../_variables';

a.product-list-item {
    text-decoration: none;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            .product-list-item-title,
            .product-list-item-price {
                color: @color-default-4;
            }
        }
    }
}

.product-list-item {
    position: relative;
    font-size: 1rem;
    text-decoration: none;

    p {
        margin-top: 0;
        margin-bottom: 0;
    }

    .product-list-item-title {
        font-family: @roboto;
        font-size: 1rem;
    }

    .product-list-item-subtitle {
        font-size: 0.86rem;
        font-family: @roboto;

        &:hover {
            font-size: 0.75rem;
        }
    }

    .product-list-item-price {
        font-size: 1rem;
        font-weight: bold;
        flex-shrink: 0;
    }

    .product-list-item-title,
    .product-list-item-price {
        transition: color @transition-duration @transition-curve;
    }

    img.product-list-item-photo {
        max-width: 100%;
        height: auto;
        min-height: 1px;
        object-fit: cover;
    }

    img.product-list-item-photo,
    .aspect-shirt,
    .product-list-item-image-hover {
        position: relative;
        margin-bottom: 0.5rem;
    }

    .product-list-item-image-hover {
        position: relative;

        img.product-list-item-photo {
            margin-bottom: 0;
        }

        img.product-list-item-art {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            transition: opacity @transition-duration @transition-curve;
            margin-bottom: 0.5rem;
            object-fit: cover;
        }

        .fast-buy{
            display: none;
            position: relative;
            font-family: "Proxima Nova", sans-serif;
            z-index: 1;
            height: fit-content;
            font-size: 1.125rem;
            font-weight: 600;
            padding: 10px 0;
        }
    }

    .icon-add-to-cart {
        position: absolute;
        bottom: 0;
        margin-bottom: 2%;
        margin-left: 73%;

        @media(max-width: 320px){
            margin-left: 70%;
        }

        .icon-cr {
            width: 30px;
            height: 30px;
            background-color: whitesmoke;
            border-radius: 20px;
            padding: 18px
        }
    }

    .product-list-item-colors {
         z-index: 2;
        display: flex;

        @media (@max-screen-sm) {
            display: flex;
            gap: 0.5rem;
        }

        .product-list-item-color {
            width: 1.25rem;
            height: 1.25rem;
            //border-radius: 50%;
            margin-bottom: 0.25rem;
            border: 1px solid @color-default-3;
            margin-right: 0.25rem;

            @media (@max-screen-sm) {
                width: 1rem;
                height: 1rem;
            }
        }

        .with-border {
            border: 1px solid @color-default-5;
        }
    }

    @media (hover: hover) and (pointer: fine) {
        a:hover {
            color: @color-default-4;
            text-decoration: none;

            .product-list-item-subtitle {
                color: @color-default-5;
                font-size: 0.86rem;

                .color-name {
                    font-size: 12px;
                }
            }

            .product-list-item-title,
            .product-list-item-price {
                color: @color-default-4;
            }

            img.product-list-item-art {
                display: block;
            }

            .fast-buy {
                display: flex;
                flex-wrap: wrap;
                text-transform: uppercase;
                font-size: 18px;

                .select-add-to-cart {
                    transition: transform 0.3s ease; /* Adicionando transição */
                    display: inline-block;
                }

                .select-add-to-cart:hover {
                    transform: scale(1.2);
                }
            }

            .first-color {
                background-color: @color-default-5;
                color: @color-white;
            }


        //    .product-list-item-colors .product-list-item-color {
        //        @item-color-levels: 1, 2, 3, 4, 5, 6;
        //        each(@item-color-levels, {
        //  &:nth-child(@{value}) {
        //    top: 0;
        //    opacity: 1;
        //    transform: scale(1);
        //  }
        //});
        //    }
        }

        .link_product:hover {
            //transition: all 0.5s ease-in-out;

            //.product-list-item-colors {
            //    display: flex;
            //    flex-direction: row;
            //    gap: 0.5rem;
            //    position: absolute;
            //}

            .product-list-item-title,
            .product-list-item-info {
                //position: relative;
                //opacity: 0.1;
            }
        }
    }
}

.second-color {
    background-color: @color-default-2;
    color: @color-black;
}

.product-list-item-top-border {
    @media (min-width: 513px) {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: darkgray;
    }
}

// <editor-fold desc="SEALS">

.product-seal {
    text-align: center;
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    border-radius: @radius-default;
    overflow: hidden;
    padding: 0.25rem 1rem;
    color: @color-white;
    width: fit-content;

    &.product-seal-primary,
    > .product-seal-primary {
        background: linear-gradient(to right, @color-default-5, @color-default-5);
    }

    &.product-seal-new {
        color: @color-black;
        background-color: @color-default-1;
    }
}

@media (max-width: 1200px) {
    .product-list .product-seal-promo > :not(:last-child) {
        display: none;
    }
}

.tag-new {
    position: absolute;
    bottom: 0;
    margin-bottom: 0.8rem;
    margin-left: 0.8rem;
}

// </editor-fold>

// <editor-fold desc="ARTISTAS CONVIDADOS">
#artists {
    h1 {
        font-size: 2.25rem;
    }

    h2 {
        font-size: 1.875rem;
    }

    p {
        text-align: justify;
        font-size: 1rem;

        @media (@max-screen-xs) {
            text-align: start;
        }
    }

    .artists-list {
        background-color: @color-backgound-default;

        p {
            font-size: 1rem;
            text-transform: uppercase;
            line-height: 0.5rem;
        }
    }

    .art-image-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: 1rem;
        row-gap: 1.5rem;

        img {
            width: 279px;
            height: 263px;
        }
    }

    .view-options {
        display: flex;
        justify-content: right;
        margin: 2rem 2rem 2rem 0;
    }

    .blocks-view {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.5rem;
    }

    .list-view {
        box-sizing: border-box;
        margin: 0 0 0.6rem 0;
        display: flex;
        justify-content: space-between;
        background-color: @color-default-1;

        p {
            font-size: 1.3rem;
            line-height: 1rem;
        }

        .img-cut {
            width: 123px;
            height: 48px;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }
}

// </editor-fold>

.product-list-item {
    &.banner {
        flex: 0 0 100%;
        .banner-wrapper {
            padding-bottom: 100%;
        }

        @media (@min-screen-xs) {
            .banner-wrapper {
                padding-bottom: 74.11%;
            }
        }

        @media (@min-screen-sm) {
            flex: 0 0 50%;
        }
    }
}

@import '../_variables';

#reamaze-wrapper {
  bottom: 0 !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4) !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  right: 1rem !important;
}

#reamaze-widget {
  display: none !important;
}

.anchored #reamaze-wrapper iframe, .anchored #reamaze-wrapper #reamaze-container {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

#reamaze-wrapper, .anchored #reamaze-wrapper iframe, .anchored #reamaze-wrapper #reamaze-container {
  //width: 370px !important;
  //height: 330px !important;
}

#olark-tab {
  display: flex;
  padding: .25rem .5rem;
  text-transform: uppercase;
  position: fixed;
  bottom: 0;
  border-top-left-radius: @radius-default;
  border-top-right-radius: @radius-default;
  right: 1rem;
  background-color: @color-default-1;
  z-index: 2;
  box-shadow: @default-shadow;
  align-items: center;
  transition: color @transition-duration @transition-curve, background-color @transition-duration @transition-curve;
  font-size: 1rem;

  &:hover {
    text-decoration: none;
    background-color: @color-default-2;
    color: @color-info-3;
  }

  svg {
    width: 13rem;
    height: 3.5rem;
  }

  .st0 {
    fill: @color-white;
  }

  .st1 {
    fill: none;
    stroke: @color-black;
    stroke-width: 1.4784;
    stroke-miterlimit: 10;
  }

  .st2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #29A619;
  }

  .st3 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: @color-white;
  }

  .st4 {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }

  .st5 {
    fill: none;
    stroke: @color-black;
    stroke-width: 3.8162;
    stroke-miterlimit: 10;
  }

  .st6 {
    fill: none;
    stroke: @color-black;
    stroke-width: 4.1694;
    stroke-miterlimit: 10;
  }

  .st7 {
    fill: none;
    stroke: @color-black;
    stroke-width: 2.8917;
    stroke-miterlimit: 10;
  }

  .st8 {
    fill: none;
    stroke: @color-black;
    stroke-width: 3.9127;
    stroke-miterlimit: 10;
  }
}

@media (max-width: 767px) {
  #olark-tab {
    display: none !important;
  }
}

@-moz-document url-prefix() {
  #olark-tab a:after {
    margin-top: -70px;
  }
}
@import '../_variables';
@import '_icons-mixins';
@import '_chat';

html {
  background-color: @color-default-2;
}

body {
  background-color: white;
}

#footer {
  font-weight: 300;
  position: relative;
  margin-top: 2rem;
  background-color: @color-default-2;

  #footer-main-content {
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    text-align: center;

  }

  &.footer-special {
    background-color: @color-white;
    padding-top: 0;

    #footer-main-content {
      padding-top: 0;

      .footer-brand {
        margin-top: 0;
      }

    }
  }

  #footer-seo-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: 300;

    a {
      text-decoration: underline;
    }

    @media (@max-screen-lg) {
      padding-top: 0;
    }
  }

  #footer-achievements {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    > *,
    img {
      height: 6rem;

      &.img-gptw {
        height: 6rem;
      }

      &.img-pacto {
        height: 4.5rem;
      }

      &.img-capitalismo {
        height: 4rem;
      }

      &.img-reciclo {
        height: 4.5rem;
      }

      &.img-peta {
        height: 4rem;
      }

      &.img-algodao {
        height: 3.5rem;
      }
    }

    > :nth-child(n + 2) {
      margin-top: 1rem;
    }

    > :not(#footer-change-world) img {
      padding: 0.5rem 0;
    }

    #footer-humanizadas img {
      padding: 0;
    }

    .cr-popover-content {
      min-width: 300px;
    }
  }

  @media (@min-screen-xs) {
    #footer-achievements {
      justify-content: start;
      padding-left: 0;

      > * {
        margin-right: 2rem;

        &:nth-child(n + 2) {
          margin-top: 0;
        }
      }
    }
  }

  #footer-main-content > :not(:last-child) {
    margin-top: 2rem;

  }

  #footer-main-content > :last-child > div {
    margin-top: 2rem;
  }

  #footer-main-content > :not(:first-child) {
    padding-left: 2rem;
  }

  #footer-logo {
    width: 9rem;
    height: 3rem;
    display: block;
    mask-position: center;
    margin: 0 auto;
  }

  #footer-social {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    justify-content: center;

    a {
      height: 2rem;
      width: 2rem;
      background-position: left;
      margin: 0 0.75rem 1rem;

      @media (hover: hover) and (pointer: fine) {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  #footer-contact {
    max-width: 15rem;
    margin-left: auto;
    margin-right: auto;
  }

  .footer-advantages-items > .d-flex {
    flex-basis: 50%;
    flex-direction: column;
    align-items: center;

    > .icon-cr {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      margin-right: 0.5rem;
    }
  }

  #footer-payments {
    align-items: center;

    .footer-payment {
      max-height: 35px;
    }
  }

  #footer-certificates img {
    height: 35px;
  }

  #footer-certificates,
  #footer-payments {
    position: relative;
    top: -0.5rem;
    left: -0.5rem;
    margin-bottom: -1rem;
    width: calc(100% + 1rem);

    > * {
      margin: 0.5rem;
      flex: 0 0 auto;
    }
  }

  ul.inline-list {
    list-style-type: none;
    padding-left: 0;

    li {
      display: inline;
    }

    li:not(:first-child) {
      position: relative;
      padding-left: 1rem;

      &:before {
        content: '-';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1rem;
        text-align: center;
        transform: translateY(-0.25em);
      }
    }
  }

  .seo-items-group {
    margin-top: 0.5rem;
  }

  @media (@min-screen-xs) {
    #footer-main-content {
      align-items: normal;
      flex-direction: row;
      padding-top: 1rem;
      padding-bottom: 1.5rem;
      text-align: left;
    }

    #footer-main-content > * {
      flex: 0 0 50%;
    }

    #footer-logo {
      background-position: left;
      position: relative;
      margin-left: 0;
    }

    #footer-social {
      justify-content: start;

      a {
        margin-left: 0;
        margin-right: 1rem;
      }
    }

    #footer-contact {
      margin: 0;
    }

    .footer-advantages-items > .d-flex {
      flex-direction: row;
      align-items: normal;
    }

    .collapsible-footer {
      display: none !important;
    }

    .form-newsletter {
      max-width: 18.572rem;
    }
  }

  @media (@min-screen-sm) {
    .seo-items-group {
      display: flex;
      align-items: baseline;

      > :first-child {
        width: 16rem;
      }
    }

    .footer-advantages-items {
      max-width: 15rem;

      > .d-flex {
        flex-basis: 40%;

        &:not(:first-child) {
          margin-top: 2rem;
        }
      }
    }
  }

  @media (@min-screen-sm) {
    #footer-main-content {
      flex-wrap: nowrap;
    }

    #footer-main-content > * {
      flex: 0 0 25%;
    }
  }

  &.footer-special {

    .empresa-item {
      float: left;
      margin-top: 0 !important;
      @media (@max-screen-md) {
        padding-left: 17px;
      }
    }

    .logo-footer {
      float: right;
      margin-top: 1.5rem;
    }
  }

  @media (@max-screen-xs) {
    #footer-newsletter {
      input,
      select,
      .btn {
        margin-bottom: 0.5rem;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    #footer-payments,
    #footer-certificates {
      justify-content: center;
    }

    .footer-advantages-items {
      display: flex;
      flex-wrap: wrap;
    }

    #footer-main-content {
      display: none !important;
    }

    .back-store-footer {
      width: 100%;
      padding: 2rem 0;
      background: #f3f0ec;

      .icon-cr {
        width: 3rem;
        height: 3rem;
        /* float: right; */
        margin-bottom: 1rem;
      }

      .back-store {
        float: right;
        position: relative;
        margin: 1rem 2rem 2rem;
      }
    }

    #footer-payments {
      padding-bottom: 20px;
    }

    .footer-payment {
      margin: 0.8rem !important;
    }

    .collapsible-footer {
      width: 100%;
      margin: 0 !important;
      display: block;
    }

    .content {
      padding: 0;
    }

    .collapsible-button {
      border: 0;
      background-color: #f3f0ec;
      color: #1e1e1d;
      cursor: pointer;
      width: 100%;
      border-bottom: 1px solid white;
      text-align: left;
      font-weight: 700;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: 0.02em;
      margin: 0;
      padding: 1.5rem 20px;
    }

    .active {
      border-bottom: none !important;
    }

    .collapsible-icon {
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 1rem;
      transition: all 0.2s ease;
    }

    .active .collapsible-icon {
      transform: rotate(90deg);
    }

    .collapsible-text {
      padding: 0 1.25rem 0 3.2rem;
      max-height: 0;
      text-align: left;
      overflow: hidden;
      color: #1e1e1d;
      background-color: #f3f0ec;
      border-bottom: 2px solid white;
      transition: max-height 0.2s ease;
      line-height: 3;
      font-size: 14px;
    }

    .sub-content {
      max-width: calc(1250px + 2 * 1rem);
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    #footer-social a {
      width: 3rem;
      height: 3rem;
      margin: 0 3.5rem 2rem 0;
    }
  }

  @media (@min-screen-xs) {
    #footer-newsletter {
      display: block;
      align-items: center;
      padding: 0 1.3rem;
      font-family: @roboto;

      > label {
        font-size: 1rem;
        font-weight: bold;
        line-height: 2.2rem;
      }

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }

      > input {
        margin-right: 0;
      }

      > .btn {
        line-height: 2.4rem;
        letter-spacing: 0.06rem;
      }

      select,
      input,
      textarea {
        &:not([type='submit']):not([type='button']):not([type='file']) {
          width: 100%;
          background: @color-default-1;
        }
      }

      > input:not(.btn):not(label) {
        flex: 1 1 auto;
      }
    }
  }
}

@media (min-width: 992px) {
  .a2hs {
    display: none;
  }
}

@media (max-width: 991px) {
  .a2hs {
    display: block;

    .banner {
      display: none;
      color: white;
      background-color: @color-default-4;
      margin: 0;
      position: fixed;
      bottom: 0;
      width: 100vw;
      height: 50px;
      z-index: 10000;

      .accept {
        width: 86%;
        height: 100%;
        float: left;
        padding: 11px 0 0 3px;
      }

      .deny {
        width: 14%;
        height: 100%;
        float: right;
        padding: 11px 3px 0 0;

        .icon-cr {
          width: 30px;
          height: 30px;
          float: right;
        }
      }

      .icone-logo {
        width: 32px;
        height: 32px;
        margin-left: 6px;
        float: left;
      }

      .message {
        float: left;
        margin: 4px 0 5px 8px;
        font-size: 14px;
      }
    }
  }
}

.input-newsletter {
  input::placeholder {
    color: @color-default-5;
    font-weight: bold;
    text-align: left;
  }
}
